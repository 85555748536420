"use client";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import Logo from "../../../assets/images/logo-header.webp";
import styles from "./header-cart.module.scss";

export const HeaderCart: FC = () => {
  return (
    <header className={styles.header}>
      <div className="container">
        <div className={styles.wrap}>
          <Link prefetch={false} href="/" className={styles.link} title="Интернет-магазин товаров для охоты и рыбалки ОхотАктив">
            <Image className={styles.logo} alt="Интернет-магазин товаров для охоты и рыбалки ОхотАктив" src={Logo} />
          </Link>
          <div className={styles.actions}>
            <p className={styles.work}>7:00-22:00</p>
            <Link
              prefetch={false}
              href="tel:88007008256"
              className={styles.tel}
              onClick={() => ym(42989679, "reachGoal", "clickOnTel")}
            >
              8 800 700-82-56
            </Link>
          </div>
          {/* <HeaderActionsProfile /> */}
        </div>
      </div>
    </header>
  );
};
